import React, { useEffect, useState } from 'react'
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';

export default function Contact() {
  let initialEnq={name:"",email:"",phone:"",city_name:"",comment:""}
  const [settingData,setSettingsData]=useState({});
  const [bank,setBank]=useState([]);
  const [enquiryData,setEnquiryData]=useState(initialEnq);
  useEffect(()=>{
    getSettings();
    getBankList();
  },[]);

  const getSettings=async ()=>{
    ApiService.get('settings/1').then((data)=>{
      if(data?.data){
        setSettingsData(data.data);
      }else{
        setSettingsData({});
      }
    }).catch((err)=>{
      toast.error(err?.response?.data);
    })
  }

  const getBankList=async ()=>{
    ApiService.post('bank/get-data',{page:1,limit:3,status:'1'}).then((data)=>{
      if(data?.Banks){
        setBank(data.Banks);
      }else{
        setBank([]);
      }
    }).catch((err)=>{
      toast.error(err?.response?.data);
    })
  }

  const changeInput=async(e,name)=>{
    setEnquiryData((prevState)=>{
      return {
      ...prevState,
      [name]: e?.target?.value
      }
    })
  }

  const submitEnquiry=()=>{
    if(!enquiryData?.email || !validateEmail(enquiryData?.email)){
      toast.error("Email Not Valid");
      return;
    }
    if(!enquiryData?.phone || !validateMobileNumber(enquiryData?.phone)){
      toast.error("Mobile Not Valid");
      return;
    }
    ApiService.post('enquiry',enquiryData).then((data)=>{
      if(data?.message){
        toast.success(data?.message)
        setEnquiryData(initialEnq)
      }else{
        toast.success("Something Went Worng")
      }
    }).catch((err)=>{
      toast.error(err?.response?.data);
    })
  }

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const validateMobileNumber = (number) => {
    // Regular expression for exactly 10 digits
    const regex = /^\d{10}$/;
    return regex.test(number);
  };
  return (
    <>
  {/* banner section  */}
  <section className="banner-section">
    <div className="banner">
      <img src="images/contact-banner-1.jpg" alt="" width="100%" />
    </div>
  </section>
  {/* banner section end  */}
  <section className="contact py-5">
    <div className="container-fluid px-lg-5">
      <div className="row">
        <div className="col-md-6 mb-3">
          <div className="contact-box p-2 mb-2" id="box-1">
            <div className="contact-box-title">
              <div className="contact-page-icon">
                <div>
                  <i className="fa fa-user-circle" />
                </div>
              </div>
              <div className="contact-title">
                <h4>Contact Details</h4>
              </div>
            </div>
            <div className="contact-data mt-3">
              <div className="address-div">
                <h6>
                  {" "}
                  <span>
                    {" "}
                    <i className="fa fa-map-marker" />{" "}
                  </span>{" "}
                  Head Office Address :{" "}
                </h6>
                <p>
                  { settingData?.address }
                </p>
              </div>
            </div>
            <div className="contact-data mt-3">
              <div className="address-div">
                <h6>
                  <span>
                    {" "}
                    <i className="fa fa-phone" />{" "}
                  </span>{" "}
                  Telephone No. : <a href=""> { settingData?.tele } </a>
                </h6>
              </div>
            </div>
            <div className="contact-data mt-3">
              <div className="address-div">
                <h6>
                  <span>
                    <i className="fa fa-phone" />{" "}
                  </span>{" "}
                  Mobile No. : <a href=""> { settingData?.mobile } </a>{" "}
                </h6>
              </div>
            </div>
            <div className="contact-data mt-3">
              <div className="address-div">
                <h6>
                  <span>
                    <i className="fa fa-whatsapp" />{" "}
                  </span>{" "}
                  Whatsapp No. : <a href=""> { settingData?.whatsapp } </a>{" "}
                </h6>
              </div>
            </div>
            <div className="contact-data mt-3">
              <div className="address-div">
                <h6>
                  <span>
                    <i className="fa fa-envelope-o" />{" "}
                  </span>{" "}
                  Email Id : <a href=""> { settingData?.email } </a>
                </h6>
              </div>
            </div>
            <div className="contact-data mt-3">
              <div className="address-div">
                <h6>
                  <span>
                    <i className="fa fa-globe" />{" "}
                  </span>{" "}
                  Website : <a href=""> { settingData?.website } </a>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="contact-page-form">
            <div className="title">
              <h3 className="text-justify">Send Us Enquiry</h3>
            </div>
            <form className="mt-4" action="">
              <div className="row">
                <div className="mb-3 col-md-6 contact-input">
                  <i className="fa fa-user-o" />
                  <input
                    value={enquiryData?.name}
                    type="text"
                    className="form-control"
                    id="Name"
                    placeholder="Enter Full Name *"
                    onChange={(e)=>changeInput(e,'name')}
                  />
                </div>
                <div className="mb-3 col-md-6 contact-input">
                  <i className="fa fa-volume-control-phone" />
                  <input
                  value={enquiryData?.phone}
                    type="tel"
                    className="form-control"
                    id="Phone"
                    placeholder="Enter Phone No. *"
                    onChange={(e)=>changeInput(e,'phone')}
                  />
                </div>
                <div className="mb-3 col-md-6 contact-input">
                  <i className="fa fa-envelope-o" />
                  <input
                  value={enquiryData?.email}
                    type="email"
                    className="form-control"
                    id="Email"
                    placeholder="Enter Email Id *"
                    onChange={(e)=>changeInput(e,'email')}
                  />
                </div>
                <div className="mb-3 col-md-6 contact-input">
                  <i className="fa fa-map-marker" />
                  <input
                  value={enquiryData?.city_name}
                    type="text"
                    className="form-control"
                    id="text"
                    placeholder="Enter City Name *"
                    onChange={(e)=>changeInput(e,'city_name')}
                  />
                </div>
                <div className="mb-3 col-12 contact-input">
                  <i className="fa fa-pencil-square-o" />
                  <textarea
                  value={enquiryData?.comment}
                    className="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: 100 }}
                    defaultValue={""}
                    onChange={(e)=>changeInput(e,'comment')}
                  />
                </div>
              </div>
              <div className="more-details-btn">
                <button type='button' onClick={()=>submitEnquiry()} className="btn btn-more-detaisl">
                  <i className="fa fa-send-o" /> Submit Your Enquiry
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="other-branches my-4">
          <h3 className="text-center">
            {" "}
            <span>Other Branches </span>
          </h3>
        </div>
        {bank && bank?.length>0 && bank?.map((b)=>{
       return(
        <div className="col-md-4 mt-3">
          <div className="contact-box p-2 mb-2" id="box-2">
            <div className="contact-data">
              <div className="address-div" id="other">
                <h6 className="text-center d-blck">
                  {" "}
                  <i className="fa fa-map-marker" /> Surat Office :{" "}
                </h6>
                <p className="other-p text-center">
                  345-Third Floor, Avadh Viceroy Sarthana Jakatnka Surat !
                </p>
              </div>
            </div>
          </div>
        </div>
       )  
      })}

        {/* <div className="col-md-4 mt-3">
          <div className="contact-box p-2 mb-2" id="box-2">
            <div className="contact-data">
              <div className="address-div" id="other">
                <h6 className="text-center d-blck">
                  {" "}
                  <i className="fa fa-map-marker" /> Manali Office :{" "}
                </h6>
                <p className="other-p text-center">
                  Aleo, Left Bank, New Manali, Distt Kullu, Himachal Pradesh –
                  175131 !
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <div className="contact-box p-2 mb-2" id="box-2">
            <div className="contact-data">
              <div className="address-div" id="other">
                <h6 className="text-center d-blck">
                  {" "}
                  <i className="fa fa-map-marker" /> Pune Office :{" "}
                </h6>
                <p className="other-p text-center">
                  Apoorvai Hall Gogateshala, Narayan Peth, Pune, Maharashtra
                  411030 !
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </section>
  <div className="map-area mt-4">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d33049.9593984797!2d77.081764!3d28.630288!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d052ee33b848f%3A0x67fd13156fd8cf45!2sMinto%20Holidays%20Private%20Limited!5e1!3m2!1sen!2sin!4v1725614561412!5m2!1sen!2sin"
      width="100%"
      height={400}
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  </div>
</>

  )
}
