import axios from 'axios';
let userData=JSON.parse(localStorage.getItem('userData'));
// Create an instance of axios with default configuration
const axiosInstance = axios.create({
  baseURL: 'http://103.171.45.91:5000/api/',  // Replace with your API base URL//103.171.45.91
  headers: {
    'Content-Type': 'application/json',
    Authorization : 'Bearer '+userData?.token,
  },
});

// Axios service object
const ApiService = {
  // Function to perform GET request
  get: async (url, config = {}) => {
    try {
      const response = await axiosInstance.get(url, config);
      return response.data;
    } catch (error) {
      handleRequestError(error);
    }
  },

  // Function to perform POST request
  post: async (url, data, config = {}) => {
    try {
      
      const response = await axiosInstance.post(url, data, config);
      return response.data;
    } catch (error) {
      handleRequestError(error);
    }
  },

  // Function to perform PUT request
  put: async (url, data, config = {}) => {
    try {
      const response = await axiosInstance.put(url, data, config);
      return response.data;
    } catch (error) {
      handleRequestError(error);
    }
  },

  // Function to perform DELETE request
  delete: async (url, config = {}) => {
    try {
      const response = await axiosInstance.delete(url, config);
      return response.data;
    } catch (error) {
      handleRequestError(error);
    }
  },
};

// Function to handle request errors
const handleRequestError = (error) => {
  console.error('Request error:', error);
  throw error;
};

export default ApiService;
